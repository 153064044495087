import $ from 'jquery';
import 'htmx.org'

window.jQuery = $;
window.$ = $;
window.htmx = require('htmx.org');

require('jquery-colorbox')
import 'jquery-colorbox/example1/colorbox.css'

require('superfish')

$(function () {
    $('ul.sf-menu').superfish({
        autoArrows: false  // disable generation of arrow mark-up, not working
    });
})